<template>
    <v-container>
      <v-banner color="warning" lines="one">
        <template v-slot:text class="app_bar_title"><span class="text-h6 text-red"> Control de Personal</span> </template>
  
        <template v-slot:actions>
          <v-btn color="red" @click="goToAddPerson" variant="tonal">
            Agregar nuevo
          </v-btn>
        </template>
      </v-banner>
      <v-data-table :headers="headers" :items="people" class="elevation-0">
        <template v-slot:item.photo="{ item }">
          <v-avatar>
            <v-img v-if="item.photo" :alt="item.name" :src="getUserPhotoUrl(item.photo.filename)"></v-img>
            <span v-else>No Photo</span>
          </v-avatar>
        </template>
        <template v-slot:item.name="{ item }">
          <span>{{ item.name.split(' ')[0] }} {{ item.last_name }}</span>
        </template>
        <template v-slot:item.number_document="{ item }">
          <span>{{ item.typeDocument.code }} - {{ item.number_document }}</span>
        </template>
        <template v-slot:item.options="{ item }">
          <div class="d-flex align-center">
            <v-btn elevation="0" variant="tonal" color="primary" v-if="item.id" @click="generateQR(item.id)">QR</v-btn>
            <v-btn elevation="0" variant="tonal" color="secondary" v-if="item.id" @click="editPerson(item.id)">Editar</v-btn>
          </div>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip size="small" :color="item.status ? 'green' : 'red'" variant="flat">
            {{ item.status ? 'Activo' : 'Inactivo' }}
          </v-chip>
        </template>
      </v-data-table>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from "vue";
  import api from "@/services/api";
  import { useRouter } from "vue-router";
  import { Person } from "@/types"; // Importa la interfaz Person
  import QRCode from 'qrcode';
  
  export default defineComponent({
    name: "ControlPersonas",
    setup() {
      const router = useRouter();
      const people = ref<Person[]>([]); // Usa la interfaz Person para tipar el array people
      const headers = [
        { title: "Nombre", value: "name" },
        { title: "Documento", value: "number_document" },
        { title: "Foto", value: "photo" },
        { title: "Tipo de Sangre", value: "bloodType.code" },
        { title: "Codigo", value: "code" },
        { title: "Cargo", value: "jobTitle.name" },
        { title: "Estado", value: "status" },
        { title: "Opciones", value: "options" },
      ];
  
      const fetchPeople = async () => {
        try {
          const response = await api.get("/peoples");
          people.value = response.data;
        } catch (error) {
          console.error(error);
        }
      };
  
      const getUserPhotoUrl = (filename: any) => {
        const baseUrl = process.env.VUE_APP_API_URL || 'https://api.v1.funaprodedh.com';
        return `${baseUrl}uploads/${filename}`;
      };
  
      const goToAddPerson = () => {
        router.push("/dashboard/add-person");
      };
  
      const generateQR = async (id: string) => {
        try {
          const url = await QRCode.toDataURL(`https://system.v1.funaprodedh.com/person/${id}`);
          const link = document.createElement('a');
          link.href = url;
          link.download = `QR_${id}.png`;
          link.click();
        } catch (error) {
          console.error('Error generating QR code:', error);
        }
      };
  
      const editPerson = (id: string) => {
        router.push(`/dashboard/edit-person/${id}`);
      };
  
      onMounted(fetchPeople);
  
      return {
        people,
        headers,
        goToAddPerson,
        getUserPhotoUrl,
        generateQR,
        editPerson,
      };
    },
  });
  </script>
<template>
    <div>
      <v-img
        class="mx-auto my-6"
        max-width="400"
        src="../assets/LOGO-FUNAPRODEDH.png"
      ></v-img>
  
      <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        max-width="448"
        rounded="lg"
      >
        <div class="text-subtitle-1 text-medium-emphasis">Usuario</div>
  
        <v-form @submit.prevent="login">
          <v-text-field
            v-model="username"
            density="compact"
            placeholder="Ingresa tu usuario"
            prepend-inner-icon="mdi-account-outline"
            variant="outlined"
            required
          ></v-text-field>
  
          <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
            Contraseña
          </div>
  
          <v-text-field
            v-model="password"
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            density="compact"
            placeholder="Ingresa tu contraseña"
            prepend-inner-icon="mdi-lock-outline"
            variant="outlined"
            @click:append-inner="visible = !visible"
            required
          ></v-text-field>
  
          <v-btn
            class="mb-8"
            color="red"
            size="large"
            variant="tonal"
            block
            type="submit"
            :loading="loading"
          >
            Ingresar
          </v-btn>
        </v-form>
      </v-card>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useToast } from 'vue-toastification';
  
  export default defineComponent({
    name: 'LoginForm',
    setup() {
      const store = useStore();
      const router = useRouter();
      const toast = useToast();
      const username = ref('');
      const password = ref('');
      const visible = ref(false);
  
      const login = async () => {
            try {
                await store.dispatch('login', { username: username.value, password: password.value });
                toast.success('Inicio de sesión exitoso');
                router.push('/dashboard');
            } catch (err) {
                const error = err as { response?: { data?: { message?: string } }; message: string };
                console.error(error);
                toast.error('Error al iniciar sesión: ' + (error.response?.data?.message || error.message));
            }
            };
  
      return {
        username,
        password,
        visible,
        login,
        loading: computed(() => store.getters.authStatus === 'loading'),
      };
    },
  });
  </script>
// store/modules/auth.ts
import { Module } from 'vuex';
import { RootState } from '../index';
import api from '@/services/api';

interface Role {
  id: string;
  code: string;
  name: string;
  description: string;
  status: boolean;
  created_at: string;
  updated_at: string;
}

interface People {
  id: string;
  number_document: string;
  name: string;
  last_name: string;
  mother_lastname: string;
  code: string;
  status: boolean;
  created_at: string;
  updated_at: string;
}

interface User {
  id: string;
  username: string;
  role: Role;
  people: People;
  status: boolean;
  created_at: string;
  updated_at: string;
}

interface AuthState {
  token: string;
  status: string;
  user: User | null;
}

const state: AuthState = {
  token: localStorage.getItem('token') || '',
  status: '',
  user: null,
};

const mutations = {
  auth_request(state: AuthState) {
    state.status = 'loading';
  },
  auth_success(state: AuthState, { token, user }: { token: string; user: User }) {
    state.status = 'success';
    state.token = token;
    state.user = user;
  },
  auth_error(state: AuthState) {
    state.status = 'error';
  },
  logout(state: AuthState) {
    state.status = '';
    state.token = '';
    state.user = null;
  },
};

const actions = {
  login({ commit }: any, user: { username: string; password: string }) {
    return new Promise<void>((resolve, reject) => {
      commit('auth_request');
      api.post('/login', user)
        .then(resp => {
          const token = resp.data.token;
          const user = resp.data.user;
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          commit('auth_success', { token, user });
          resolve();
        })
        .catch(err => {
          commit('auth_error');
          localStorage.removeItem('token');
          reject(err);
        });
    });
  },
  logout({ commit }: any) {
    return new Promise<void>(resolve => {
      commit('logout');
      localStorage.removeItem('token');
      delete api.defaults.headers.common['Authorization'];
      resolve();
    });
  },
};

const getters = {
  isLoggedIn: (state: AuthState) => !!state.token,
  authStatus: (state: AuthState) => state.status,
  user: (state: AuthState) => state.user,
};

const auth: Module<AuthState, RootState> = {
  state,
  mutations,
  actions,
  getters,
};

export default auth;
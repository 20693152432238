<template>
    <v-container>
      <v-card class="mx-auto card-main" max-width="800" flat>
        <v-card-title>
          <span class="text-h5">Datos del defensor</span>
        </v-card-title>
        <v-card-text>
          <v-row v-if="person">
            <v-col cols="3" class="text-center">
              <v-img
                :src="getUserPhotoUrl(person.photo.filename)"
                alt="Foto de la persona"
                max-width="300"
              ></v-img>
            </v-col>
            <v-col cols="9">
              <v-row>
                <v-col cols="12" md="6">
                  <v-list class="bg-none" dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Nombre</v-list-item-title>
                        <v-list-item-subtitle>{{ person.name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Apellido</v-list-item-title>
                        <v-list-item-subtitle>{{ person.last_name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Apellido Materno</v-list-item-title>
                        <v-list-item-subtitle>{{ person.mother_lastname }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Número de Documento</v-list-item-title>
                        <v-list-item-subtitle>{{ person.typeDocument.code }} V-{{ person.number_document }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Tipo de Sangre</v-list-item-title>
                        <v-list-item-subtitle>{{ person.bloodType.name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-list class="bg-none" dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Cargo</v-list-item-title>
                        <v-list-item-subtitle>{{ person.jobTitle.name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Código</v-list-item-title>
                        <v-list-item-subtitle>{{ person.code }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Estado</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip class="mt-5" size="x-large" :color="person.status ? 'green' : 'red'" variant="flat">
                            {{ person.status ? "Activo" : "Inactivo" }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-alert v-else type="error">Cargando datos de la persona...</v-alert>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from "vue";
  import api from "@/services/api";
  import { useRoute } from "vue-router";
  import { Person } from "@/types";
  
  export default defineComponent({
    name: "PersonDetails",
    setup() {
      const route = useRoute();
      const person = ref<Person | null>(null);
  
      const fetchPerson = async () => {
        try {
          const response = await api.get(`/peoples/${route.params.id}`);
          person.value = response.data;
        } catch (error) {
          console.error("Error fetching person:", error);
        }
      };
  
      const getUserPhotoUrl = (filename: string) => {
        const baseUrl = process.env.VUE_APP_API_URL || "https://api.v1.funaprodedh.com";
        return `${baseUrl}uploads/${filename}`;
      };
  
      onMounted(fetchPerson);
  
      return {
        person,
        getUserPhotoUrl,
      };
    },
  });
  </script>
  
  <style scoped>
  .text-center {
    text-align: center;
  }
  
  .card-main {
    position: relative;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8); /* Fondo semi-transparente */
  }
  
  .card-main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../assets/logo-ddhh.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.05; /* Ajusta la opacidad según sea necesario */
    z-index: -1;
  }
  
  .card-main::after {
    content: "";
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 100px;
    height: 100px;
    background-image: url(../assets/logo-ddhh.png); /* Usa la misma imagen */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3; /* Ajusta la opacidad según sea necesario */
    z-index: -1;
  }
  
  .bg-none {
    background-color: transparent;
  }
  </style>
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/LOGO-FUNAPRODEDH.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_img, {
      class: "mx-auto my-6",
      "max-width": "400",
      src: _imports_0
    }),
    _createVNode(_component_v_card, {
      class: "mx-auto pa-12 pb-8",
      elevation: "8",
      "max-width": "448",
      rounded: "lg"
    }, {
      default: _withCtx(() => [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-subtitle-1 text-medium-emphasis" }, "Usuario", -1)),
        _createVNode(_component_v_form, {
          onSubmit: _withModifiers(_ctx.login, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.username,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
              density: "compact",
              placeholder: "Ingresa tu usuario",
              "prepend-inner-icon": "mdi-account-outline",
              variant: "outlined",
              required: ""
            }, null, 8, ["modelValue"]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between" }, " Contraseña ", -1)),
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
              "append-inner-icon": _ctx.visible ? 'mdi-eye-off' : 'mdi-eye',
              type: _ctx.visible ? 'text' : 'password',
              density: "compact",
              placeholder: "Ingresa tu contraseña",
              "prepend-inner-icon": "mdi-lock-outline",
              variant: "outlined",
              "onClick:appendInner": _cache[2] || (_cache[2] = ($event: any) => (_ctx.visible = !_ctx.visible)),
              required: ""
            }, null, 8, ["modelValue", "append-inner-icon", "type"]),
            _createVNode(_component_v_btn, {
              class: "mb-8",
              color: "red",
              size: "large",
              variant: "tonal",
              block: "",
              type: "submit",
              loading: _ctx.loading
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Ingresar ")
              ])),
              _: 1
            }, 8, ["loading"])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    })
  ]))
}
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import ControlPersonas from '../views/ControlPersonas.vue';
import AddPerson from '../views/AddPerson.vue';
import EditPerson from '@/views/EditPerson.vue';
import PersonDetails from '@/views/PersonDetails.vue';

import store from '../store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/person/:id',
    name: 'PersonDetails',
    component: PersonDetails,
    props: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'control-personas',
        name: 'ControlPersonas',
        component: ControlPersonas,
      },
      {
        path: '/dashboard/edit-person/:id',
        name: 'EditPerson',
        component: EditPerson,
        props: true,
      },
      {
        path: 'add-person',
        name: 'AddPerson',
        component: AddPerson,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
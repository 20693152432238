<template>
    <div>
      <!-- Input de archivo deshabilitado cuando está subiendo -->
      <v-file-input
        type="file"
        label="Seleccionar imagen"
        @change="onFileChange"
        :disabled="isUploading"
      />
      <div v-if="imageUrl">
        <vue-cropper
          ref="cropper"
          :src="imageUrl"
          :aspect-ratio="4 / 6"
          style="height: 400px; width: 100%;"
        />
        <!-- Botón de carga deshabilitado mientras está subiendo -->
        <v-btn @click="cropImage" :disabled="isUploading">Recortar y Subir</v-btn>
      </div>
      <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
  
      <!-- Snackbar para mostrar el mensaje de éxito o error -->
      <v-snackbar v-model="snackbar" :timeout="3000" top>
        {{ snackbarMessage }}
      </v-snackbar>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  import api from '@/services/api';
  
  export default defineComponent({
    name: 'ImageCropper',
    components: {
      VueCropper,
    },
    emits: ['imageUploaded'],
    setup(_, { emit }) {
      const imageUrl = ref<string | null>(null);
      const loading = ref(false);
      const cropper = ref<InstanceType<typeof VueCropper> | null>(null);
      const originalFileName = ref<string>('');
      const snackbar = ref(false); // Para mostrar el snackbar
      const snackbarMessage = ref(''); // El mensaje que se mostrará en el snackbar
      const isUploading = ref(false); // Estado para bloquear la subida hasta que se guarde la persona
  
      // Este método controla la selección del archivo
      const onFileChange = (e: Event) => {
        if (isUploading.value) return; // Evita seleccionar archivo mientras se sube uno
        const files = (e.target as HTMLInputElement)?.files;
        if (files && files.length > 0) {
          originalFileName.value = files[0].name;
          const reader = new FileReader();
          reader.onload = () => {
            imageUrl.value = reader.result as string;
          };
          reader.readAsDataURL(files[0]);
        }
      };
  
      // Método que realiza la carga de la imagen recortada
      const cropImage = async () => {
        if (isUploading.value || !cropper.value) return; // Si ya está subiendo o no hay cropper, no hacer nada
        isUploading.value = true; // Bloquea la posibilidad de cargar una imagen nueva
  
        loading.value = true; // Muestra el círculo de carga
  
        const canvas = cropper.value.getCroppedCanvas({
          width: 860,
          height: 540,
        });
  
        if (canvas) {
          canvas.toBlob(async (blob: Blob | null) => {
            if (blob) {
              const formData = new FormData();
              formData.append('file', blob, 'cropped-image.jpg');
              formData.append('name', originalFileName.value);
              formData.append('type', 'image/jpeg');
              formData.append('size', blob.size.toString());
              formData.append('status', 'true');
  
              try {
                // Realiza la subida del archivo
                const response = await api.post('/photos', formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                });
                emit('imageUploaded', response.data.id);
                snackbarMessage.value = 'Imagen subida con éxito';
                snackbar.value = true; // Muestra el mensaje de éxito
              } catch (error) {
                console.error('Error uploading image:', error);
                snackbarMessage.value = 'Error al subir la imagen';
                snackbar.value = true; // Muestra el mensaje de error
              } finally {
                loading.value = false;
                isUploading.value = false; // Permite cargar otra imagen una vez procesado
              }
            } else {
              console.error('Canvas is null. Cropping failed.');
              loading.value = false;
              isUploading.value = false;
            }
          }, 'image/jpeg');
        } else {
          console.error('Canvas is null. Cropping failed.');
          loading.value = false;
          isUploading.value = false;
        }
      };
  
      return {
        imageUrl,
        loading,
        onFileChange,
        cropImage,
        cropper,
        snackbar,
        snackbarMessage,
        isUploading, // Para controlar si está subiendo o no
      };
    },
  });
  </script>
  
  <style scoped>
  /* Añadir estilos adicionales aquí */
  </style>
  
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_vue_cropper = _resolveComponent("vue-cropper")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_file_input, {
      type: "file",
      label: "Seleccionar imagen",
      onChange: _ctx.onFileChange,
      disabled: _ctx.isUploading
    }, null, 8, ["onChange", "disabled"]),
    (_ctx.imageUrl)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_vue_cropper, {
            ref: "cropper",
            src: _ctx.imageUrl,
            "aspect-ratio": 4 / 6,
            style: {"height":"400px","width":"100%"}
          }, null, 8, ["src"]),
          _createVNode(_component_v_btn, {
            onClick: _ctx.cropImage,
            disabled: _ctx.isUploading
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Recortar y Subir")
            ])),
            _: 1
          }, 8, ["onClick", "disabled"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_v_progress_circular, {
          key: 1,
          indeterminate: "",
          color: "primary"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: 3000,
      top: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.snackbarMessage), 1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_banner = _resolveComponent("v-banner")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_banner, {
        color: "warning",
        lines: "one"
      }, {
        text: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "text-h6 text-red" }, " Control de Personal", -1)
        ])),
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            color: "red",
            onClick: _ctx.goToAddPerson,
            variant: "tonal"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Agregar nuevo ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_data_table, {
        headers: _ctx.headers,
        items: _ctx.people,
        class: "elevation-0"
      }, {
        "item.photo": _withCtx(({ item }) => [
          _createVNode(_component_v_avatar, null, {
            default: _withCtx(() => [
              (item.photo)
                ? (_openBlock(), _createBlock(_component_v_img, {
                    key: 0,
                    alt: item.name,
                    src: _ctx.getUserPhotoUrl(item.photo.filename)
                  }, null, 8, ["alt", "src"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_1, "No Photo"))
            ]),
            _: 2
          }, 1024)
        ]),
        "item.name": _withCtx(({ item }) => [
          _createElementVNode("span", null, _toDisplayString(item.name.split(' ')[0]) + " " + _toDisplayString(item.last_name), 1)
        ]),
        "item.number_document": _withCtx(({ item }) => [
          _createElementVNode("span", null, _toDisplayString(item.typeDocument.code) + " - " + _toDisplayString(item.number_document), 1)
        ]),
        "item.options": _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_2, [
            (item.id)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  elevation: "0",
                  variant: "tonal",
                  color: "primary",
                  onClick: ($event: any) => (_ctx.generateQR(item.id))
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("QR")
                  ])),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true),
            (item.id)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 1,
                  elevation: "0",
                  variant: "tonal",
                  color: "secondary",
                  onClick: ($event: any) => (_ctx.editPerson(item.id))
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Editar")
                  ])),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        "item.status": _withCtx(({ item }) => [
          _createVNode(_component_v_chip, {
            size: "small",
            color: item.status ? 'green' : 'red',
            variant: "flat"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.status ? 'Activo' : 'Inactivo'), 1)
            ]),
            _: 2
          }, 1032, ["color"])
        ]),
        _: 1
      }, 8, ["headers", "items"])
    ]),
    _: 1
  }))
}
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Toast, { PluginOptions } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import '@mdi/font/css/materialdesignicons.css'; // Importa los iconos de Material Design

const options: PluginOptions = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
};

const app = createApp(App);

app.use(store);
app.use(router);
app.use(vuetify);
app.use(Toast, options);

app.mount('#app');
// store/index.ts
import { createStore } from 'vuex';
import auth from './modules/auth';

export interface RootState {
  // Define tu estado raíz aquí
  // Por ejemplo, si tienes más módulos, puedes agregarlos aquí
}

const store = createStore<RootState>({
  modules: {
    auth,
  },
});

export default store;
<template>
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" permanent>

        <v-list>
          <v-list-item v-if="user"
            :prepend-avatar="getUserPhotoUrl(user?.people?.photo?.filename)"
            :subtitle="user?.role?.name"
            :title="`${user?.people?.name?.split(' ')[0]} ${user?.people?.last_name}`"
          >
            <template v-slot:append>
              <v-btn
                icon="mdi-menu-down"
                size="small"
                variant="text"
              ></v-btn>
            </template>
          </v-list-item>
        </v-list>
  
        <v-divider></v-divider>
  
        <v-list :lines="false" density="compact" nav>
          <v-list-item v-for="(item, i) in items" :key="i" :value="item" @click="navigate(item.link)">
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
  
      <v-app-bar flat >
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-app-bar-title>FUNAPRODEDH</v-app-bar-title>
      </v-app-bar>
      <div class="app_bar"> 
        
      </div>
  
      <v-main class="main">
        <router-view></router-view>
      </v-main>
    </v-app>
  </template>
  
  <script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  
  const drawer = ref(true); // Inicializa el drawer como abierto
  const store = useStore();
  const router = useRouter();
  const user = ref(null);
  
  onMounted(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      user.value = JSON.parse(userData);
    }
  });
  
  const items = [
    { text: 'Inicio', icon: 'mdi-home', link: '/dashboard/' },
    { text: 'Control de Personas', icon: 'mdi-account-group', link: '/dashboard/control-personas' }
  ];
  
  const navigate = (link) => {
    router.push(link);
  };
  
  const getUserPhotoUrl = (filename) => {
    const baseUrl = process.env.VUE_APP_API_URL || 'https://api.v1.funaprodedh.com';
    return `${baseUrl}uploads/${filename}`;
  };
  </script>

<style scoped lang="scss">
.app_bar{
    height: 70px;
    width: 100%;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,9,9,1) 35%, rgba(255,0,0,1) 100%);
    }

.main{
    background-color: rgb(230, 230, 230)
}
</style>
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_image_cropper = _resolveComponent("image-cropper")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, {
        onSubmit: _withModifiers(_ctx.addPerson, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_autocomplete, {
            modelValue: _ctx.person.typeDocument.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.person.typeDocument.id) = $event)),
            items: _ctx.formattedTypeDocuments,
            "item-title": "name",
            "item-value": "id",
            label: "Tipo de Documento",
            required: ""
          }, null, 8, ["modelValue", "items"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.person.number_document,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.person.number_document) = $event)),
            label: "Número de Documento",
            required: "",
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toUpperCase('number_document')))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.person.name,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.person.name) = $event)),
            label: "Nombre",
            required: "",
            onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toUpperCase('name')))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.person.last_name,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.person.last_name) = $event)),
            label: "Apellido",
            required: "",
            onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toUpperCase('last_name')))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.person.mother_lastname,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.person.mother_lastname) = $event)),
            label: "Apellido Materno",
            onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.toUpperCase('mother_lastname')))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.person.code,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.person.code) = $event)),
            label: "Código",
            required: "",
            onInput: _cache[10] || (_cache[10] = ($event: any) => (_ctx.toUpperCase('code')))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_autocomplete, {
            modelValue: _ctx.person.bloodType.id,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.person.bloodType.id) = $event)),
            items: _ctx.formattedBloodTypes,
            "item-title": "name",
            "item-value": "id",
            label: "Tipo de Sangre",
            required: ""
          }, null, 8, ["modelValue", "items"]),
          _createVNode(_component_v_autocomplete, {
            modelValue: _ctx.person.jobTitle.id,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.person.jobTitle.id) = $event)),
            items: _ctx.formattedJobTitles,
            "item-title": "name",
            "item-value": "id",
            label: "Cargo",
            required: ""
          }, null, 8, ["modelValue", "items"]),
          _createVNode(_component_image_cropper, { onImageUploaded: _ctx.onImageUploaded }, null, 8, ["onImageUploaded"]),
          _createVNode(_component_v_btn, {
            type: "submit",
            color: "primary"
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode("Agregar")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onSubmit"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showErrorDialog,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.showErrorDialog) = $event)),
        "max-width": "500"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode("Error")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "red darken-1",
                    text: "",
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.showErrorDialog = false))
                  }, {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createTextVNode("Cerrar")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}